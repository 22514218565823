@tailwind base;
@tailwind components;
@tailwind utilities;

#title {
  font-family: 'Raleway', sans-serif;
}

html {
  font-family: 'Montserrat', sans-serif;
}